.image-container {
  width: 264px; /* Adjust the width to your desired size */
  height: 148.8px;
  border-radius: 0px;
  margin-bottom: -13px;
  margin-top: 6px;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.image-container img {
  width: 100%;
  height: 100%;
}

.inner-banner-description {
  font-size: 17px;
  font-family: SFProDisplay-Semibold;
  line-height: 1.41;
  letter-spacing: -0.3px;
  font-style: normal;
  color: #757579;
  text-align: left;
}
.inner-banner-title {
  font-size: 28px;
  font-family: SFProDisplay-Semibold;
  line-height: 1.14;
  letter-spacing: -0.2px;
  font-style: normal;
  color: #fff;
  text-align: left;
}

.Show-all {
  font-family: SFProDisplay-Semibold;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.3px;
  text-align: left;
}

.overlay-subbanner {
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Adjust color and opacity (alpha channel) as needed */
}

.lockImage {
  // z-index: 3;
  position: absolute;
  height: 28.1px;
  width: 28.1px;
  margin-top: 50.8px;
  margin-left: 117px;
  margin-bottom: 60.8px;
  margin-right: 118.8px;
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Adjust color and opacity (alpha channel) as needed */
}

.inner-banner-component {
  width: 264px;
  height: 148.8px;
  float: right;
  padding-right: 79px;
  padding-top: 56px;
}
.banner-desc-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}
@media only screen and (min-width: 768px) {
  .bg-banner
  {
   aspect-ratio: 31/9.1;
  }
  .banner-desc-clamp {
  width: 740px;
}
}
@media only screen and (min-width: 1360px) {
  .banner-desc-clamp {
  width: 823px;
}
}
@media only screen and (min-width: 1360px) {
  .banner-desc-clamp {
  width: 907px;
}
}
.collection-heading-block-mobile {
  padding-left: 17px;
  padding-right: 17px;
  flex: auto;
  text-align: left;
  padding-top: 22px;
}
.collection-heading-block-mobile .MuiTypography-h1 {
  font-family: SFProDisplay-Semibold;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.2px;
}
.mobile-description-padding
{
  padding-left: 17px;
  padding-right: 17px;
}

.paddingTopHeader {
  padding-right: 16px;
  padding-bottom: 16px;
}
